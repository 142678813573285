import React from "react";
import { formatNumber, NATIVE, trimContent } from "../services/Helper";
import { useNavigate } from "react-router-dom";

export const TokensList = ({
  network,
  balance,
  importTokenList,
  isLoading,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="tokens-list">
        <ul>
          <li
            onClick={() => {
              let data = {
                chainId: network?.chainId,
                contractAddress: NATIVE,
                decimals:
                  network?.blockchainType.toUpperCase() === "TRON" ? 6 : 18,
                name: network?.name,
                symbol: network?.symbol,
              };

              navigate("/send-crypto", {
                state: data,
              });
            }}
          >
            <div className="tokens-left-box">
              <figure>
                <img src={network?.icon} alt="tokens" />
              </figure>
              <figcaption>
                <h3>{network?.name}</h3>
                <p>{network?.symbol}</p>
              </figcaption>
            </div>
            <div className="tokens-right-box">
              <figcaption>
                <h3>
                  {formatNumber(balance ?? 0, 5)} {network?.symbol}
                </h3>
              </figcaption>
            </div>
          </li>
          {!isLoading && importTokenList && importTokenList.length > 0
            ? importTokenList.map((item, key) => (
                <li
                  key={key}
                  onClick={() => {
                    console.log("Item is : ", item);

                    let data = {
                      chainId: network?.chainId,
                      contractAddress: item?.token_address,
                      decimals: item?.decimals,
                      name: item?.name,
                      symbol: item?.symbol,
                    };
                    navigate("/send-crypto", {
                      state: data,
                    });
                  }}
                >
                  <div className="tokens-left-box">
                    <figure>
                      <img src={network?.icon} alt="tokens" />
                    </figure>
                    <figcaption>
                      <h3>{trimContent(item?.name, 10)}</h3>
                      <p>{trimContent(item?.symbol, 6)}</p>
                    </figcaption>
                  </div>
                  <div className="tokens-right-box">
                    <figcaption>
                      <h3>
                        {formatNumber(
                          item?.balance / 10 ** item?.decimals ?? 0,
                          3
                        )}{" "}
                        {item?.symbol}
                      </h3>
                    </figcaption>
                  </div>
                </li>
              ))
            : null}
        </ul>
      </div>
    </>
  );
};
